import React, {
  FC,
  createContext,
  Dispatch,
  ReactNode,
  useReducer,
} from 'react';
import { Portal } from 'react-portal';
import Overlay from './overlay';
import overlayReducer from './overlay.reducer';
import { OverlayState } from './overlay.state';
import pop from './pop.overlay.actions';

const initialState: OverlayState = {
  components: [],
  isHeaderSearch: false,
  keepNav: false,
  lockScroll: true,
  isAccount: false,
  isMultiAdd: false,
};

export const OverlayDispatchContext = createContext<Dispatch<unknown>>(
  () => null
);
export const OverlayStateContext = createContext<OverlayState>(initialState);

export interface Props {
  children: ReactNode;
}

const OverlayProvider: FC<Props> = ({ children }: Props) => {
  const [state, dispatch] = useReducer(overlayReducer, initialState);

  return (
    <OverlayDispatchContext.Provider value={dispatch}>
      <OverlayStateContext.Provider value={state}>
        {children}
        <Portal>
          <Overlay
            keepNav={state.keepNav}
            isHeaderSearch={state.isHeaderSearch}
            lockScroll={state.lockScroll}
            components={state.components}
            onClose={() => dispatch(pop())}
          />
        </Portal>
      </OverlayStateContext.Provider>
    </OverlayDispatchContext.Provider>
  );
};

export default OverlayProvider;
