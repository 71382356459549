import CookieKeysEnum from '../../../../models/cookie-keys-enum';
import Primitive from '../../../../types/primitive';
import { AuthState } from '../user.state';
import initialState from './initial';

export default function retrieve(cookies: {
  [key: string]: Primitive;
}): AuthState {
  if (CookieKeysEnum.Session in cookies) {
    const sessionData = JSON.parse(cookies[CookieKeysEnum.Session] as string);

    if (sessionData && sessionData.sessionExpiresAt) {
      const sessionExpireAt = new Date(sessionData.sessionExpiresAt);
      const currentTime = new Date();

      if (sessionExpireAt > currentTime) {
        return sessionData;
      }
    }
  }

  return initialState;
}
