import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { UserStateContext } from '../user/user.provider';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import { GtmDataType } from '../../../models/gtm-data-type';

export interface DataLayerContextType {
  pushToDataLayer: (name: GtmEventNameEnum, pendingData?: GtmDataType) => void;
}

export const DataLayerContext = createContext<DataLayerContextType>(
  {} as DataLayerContextType
);

const DataLayerProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { ready, user } = useContext(UserStateContext);
  const [events, setEvents] = useState<Array<object>>([]);

  useEffect(() => {
    if (!ready || !events.length) {
      return;
    }

    events.forEach((event: { name: string; pendingData: object }) => {
      const request = {
        ...event.pendingData,
        event: event.name,
        userType: !user ? 'Guest' : 'Account',
        accountNumber: undefined,
        accountType: undefined,
        creditType: undefined,
      };

      if (user) {
        request.accountNumber = user.accountNumber.toString();
        request.accountType = user.companyType;
        request.creditType = user.isProforma ? 'Non Credit' : 'Credit';
      }

      window.dataLayer?.push(request);
    });

    setEvents([]);
  }, [user, ready, events]);

  return (
    <DataLayerContext.Provider
      value={{
        pushToDataLayer: async (
          name: GtmEventNameEnum,
          pendingData?: GtmDataType
        ) => {
          setEvents((oldEvents) => [...oldEvents, { name, pendingData }]);
        },
      }}
    >
      {children}
    </DataLayerContext.Provider>
  );
};

export default DataLayerProvider;
