import React, { FC, ReactNode } from 'react';
import AnimationToggleOverlay from '../../animations/animation-toggle-overlay/animation-toggle-overlay';
import AnimationToggleOverlayContent from '../../animations/animation-toggle-overlay-content/animation-toggle-overlay-content';
import useScrollLock from '../../../hooks/use-scroll-lock/use-scroll-lock';

export interface Props {
  readonly components: ReactNode[];
  readonly isHeaderSearch: boolean;
  readonly keepNav: boolean;
  readonly lockScroll: boolean;
  readonly onClose: () => void;
}

const Overlay: FC<Props> = ({
  components,
  isHeaderSearch,
  keepNav,
  onClose,
  lockScroll = true,
}: Props) => {
  const isActive = components.length > 0;
  useScrollLock(isActive, lockScroll);

  const getClassNames = (): string => {
    if (keepNav) {
      return isHeaderSearch
        ? 'overflow-auto top-header-mobile lg:top-header-no-nav'
        : ' top-header-mobile lg:top-header';
    }

    return 'top-0';
  };

  return (
    <>
      <AnimationToggleOverlay keepNav={keepNav} isActive={isActive}>
        <div
          className={
            `${keepNav ? 'z-30' : 'z-40'} ` +
            'fixed inset-0 bg-grey-800 bg-opacity-20'
          }
        />
      </AnimationToggleOverlay>
      <AnimationToggleOverlayContent isActive={isActive}>
        <div
          className={`${getClassNames()} bottom-0 fixed left-0 right-0 z-50`}
          role="presentation"
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              onClose();
            }
          }}
        >
          {components}
        </div>
      </AnimationToggleOverlayContent>
    </>
  );
};

export default Overlay;
