import { State } from '../basket.state';

export default <State>{
  basket: null,
  basketInvalidated: false,
  confirmedBackOrderSkus: [],
  id: null,
  isLoading: false,
  itemCount: null,
  quote: null,
  recentId: null,
  total: null,
  lastAddedVariantSkus: [],
  multiAddFormState: {
    items: [
      { text: '', quantity: '' },
      { text: '', quantity: '' },
      { text: '', quantity: '' },
      { text: '', quantity: '' },
      { text: '', quantity: '' },
    ],
  },
};
