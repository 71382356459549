enum BasketActions {
  BasketLoading,
  FetchBasket,
  InvalidateBasket,
  RemoveQuote,
  RemoveRecentBasketId,
  Replace,
  ReplaceConfirmedBackOrderSkus,
  Reset,
  UpdateBasket,
  UpdateBasketQuote,
  UpdateBasketTotal,
  UpdateRecentBasketId,
  SetLastAddedVariantSkus,
  SetMultiAddFormState,
}

export default BasketActions;
