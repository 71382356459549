import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import useFirePageChangeEvents from '../../../hooks/use-fire-page-change-events/use-fire-page-change-events';
import useRefreshOnNewBuild from '../../../hooks/use-refresh-on-new-build/use-refresh-on-new-build';
import { ContentPageHeaderNavType } from '../../../models/content-page-header-nav-type';
import pageProductNav from '../../../services/content-api/endpoints/page/productNav';

export const NavigationStateContext =
  createContext<ContentPageHeaderNavType>(null);

export interface Props {
  children: ReactNode;
}

const getInitialProps = async (): Promise<ContentPageHeaderNavType> => {
  const response = await pageProductNav();

  return response;
};

const NavigationProvider: FC<Props> = ({ children }: Props) => {
  const [state, setState] = useState<ContentPageHeaderNavType>();

  useRefreshOnNewBuild();
  useFirePageChangeEvents();

  useEffect(() => {
    getInitialProps().then((productNav: ContentPageHeaderNavType) => {
      setState(productNav);
    });
  }, []);

  return (
    <NavigationStateContext.Provider value={state}>
      {children}
    </NavigationStateContext.Provider>
  );
};

export default NavigationProvider;
