import Action from '../../../types/action';
import UserActions from './actions';
import initialState from './persistence/initial';
import { State } from './user.state';

const userReducer = (state: State, action: Action<UserActions>): State => {
  if (action.type === UserActions.UpdateReady) {
    return {
      ...state,
      ready: action.payload,
    };
  }

  if (action.type === UserActions.UpdateUser) {
    return {
      ...state,
      user: {
        ...state.user,
        ...action.payload,
      },
    };
  }

  if (action.type === UserActions.DeleteUser) {
    return {
      ...state,
      user: null,
    };
  }

  if (action.type === UserActions.UpdateAuth) {
    return {
      ...state,
      auth: {
        ...state.auth,
        ...action.payload,
      },
    };
  }

  if (action.type === UserActions.DeleteAuth) {
    return {
      ...state,
      auth: initialState,
    };
  }

  return state;
};

export default userReducer;
