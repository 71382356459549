import { DependencyList, useEffect } from 'react';

export default function useIntervalEffect(
  callback: () => void,
  milliseconds: number,
  deps?: DependencyList
): void {
  useEffect(() => {
    const interval = setInterval(() => callback(), milliseconds);

    callback();

    return () => clearInterval(interval);
  }, [...deps, milliseconds]);
}
