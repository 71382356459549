import { ContentPageHeaderNavType } from '../../../../models/content-page-header-nav-type';
import CultureCodeEnum from '../../../../models/culture-code-enum';
import client from '../../client-unauthenticated';

const pageProductNav = async (): Promise<ContentPageHeaderNavType> => {
  const { data } = await client.get<ContentPageHeaderNavType>(
    '/page/productNav',
    {
      params: {
        cultureCode: CultureCodeEnum.EnGb,
        domain: process.env.NEXT_PUBLIC_DOMAIN,
      },
    }
  );

  return data;
};

export default pageProductNav;
