import Action from '../../../../types/action';
import BasketActions from '.';
import { BasketType } from '../../../../models/basket-type';

const updateBasket = (basket: BasketType): Action<BasketActions> => {
  return {
    type: BasketActions.UpdateBasket,
    payload: basket,
  };
};

export default updateBasket;
