import { useContext } from 'react';
import dayjs from 'dayjs';
import { UserStateContext, UserDispatchContext } from '../user.provider';
import accountGuestSession from '../../../../services/customer-api/endpoints/account/guest-session';
import updateAuth from '../actions/update-auth';
import accountSession from '../../../../services/customer-api/endpoints/account/session';

// Prevent multiple requests for sessionId by keeping track of the request
let getSessionIdRequest = null;

const useGetSessionId = (): (() => Promise<string>) => {
  const { auth } = useContext(UserStateContext);
  const dispatch = useContext(UserDispatchContext);

  return async () => {
    if (!auth) {
      return null;
    }

    let { sessionId } = auth;

    if (getSessionIdRequest) {
      sessionId = (await getSessionIdRequest).sessionId;
    }

    if (!sessionId) {
      getSessionIdRequest = auth.accessToken
        ? accountSession(auth.accessToken)
        : accountGuestSession();

      sessionId = (await getSessionIdRequest).sessionId;

      dispatch(
        updateAuth({
          sessionId,
          sessionExpiresAt: dayjs().add(1, 'hour').toISOString(),
          sessionHardRefreshTime: dayjs().add(12, 'hour').toISOString(),
        })
      );
    }

    return sessionId;
  };
};

export default useGetSessionId;
