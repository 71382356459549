import { ProductBasketLineItemType } from '../models/product-basket-line-item-type';

const calculateLineItemQuantity = (
  lineItem: ProductBasketLineItemType
): { backOrder: number; total: number } => ({
  backOrder:
    lineItem.quantitySatisfiedSlot1 +
    lineItem.quantitySatisfiedSlot2 +
    lineItem.quantitySatisfiedSlot3,
  total:
    lineItem.quantitySatisfied +
    lineItem.quantitySatisfiedSlot1 +
    lineItem.quantitySatisfiedSlot2 +
    lineItem.quantitySatisfiedSlot3,
});

export default calculateLineItemQuantity;
