import { BroadcastChannel } from 'broadcast-channel';
import dayjs from 'dayjs';
import nookies from 'nookies';
import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useEffect,
  useReducer,
} from 'react';
import useClientOnlyLayoutEffect from '../../../hooks/use-client-only-layout-effect/use-client-only-layout-effect';
import useIntervalEffect from '../../../hooks/use-interval-effect/use-interval-effect';
import contentClient from '../../../services/content-api/client';
import customerClient from '../../../services/customer-api/client';
import accountDetails from '../../../services/customer-api/endpoints/account/details';
import accountSession from '../../../services/customer-api/endpoints/account/session';
import oauthToken, {
  OauthGrantType,
} from '../../../services/customer-api/endpoints/oauth/token';
import searchClient from '../../../services/search-api/client';
import deleteAuth from './actions/delete-auth';
import deleteUser from './actions/delete-user';
import updateAuth from './actions/update-auth';
import updateUser from './actions/update-user';
import updateReady from './actions/update-ready';
import initial from './persistence/initial';
import retrieve from './persistence/retrieve';
import store from './persistence/store';
import userReducer from './user.reducer';
import { AuthState, State } from './user.state';

const clients = [contentClient, customerClient, searchClient];

const broadcastChannel =
  typeof window !== 'undefined'
    ? new BroadcastChannel<AuthState>('user-provider-channel')
    : null;

export const UserDispatchContext = createContext<Dispatch<unknown>>(() => null);
export const UserStateContext = createContext<State>(null);

export interface Props {
  children: ReactNode;
}

const getInitialProps = async (): Promise<AuthState> => {
  let auth: AuthState = retrieve(nookies.get());

  if (
    auth.accessTokenExpiresAt &&
    Date.now() >= Date.parse(auth.accessTokenExpiresAt)
  ) {
    try {
      const response = await oauthToken({
        grantType: OauthGrantType.RefreshToken,
        refreshToken: auth.refreshToken,
      });

      const { sessionId } = await accountSession(response.access_token);

      auth = {
        accessToken: response.access_token,
        accessTokenExpiresAt: dayjs()
          .add(response.expires_in, 'second')
          .toISOString(),
        refreshToken: response.refresh_token,
        sessionId,
        sessionExpiresAt: dayjs().add(1, 'hour').toISOString(),
        sessionHardRefreshTime: dayjs().add(12, 'hour').toISOString(),
      };
    } catch {
      return initial;
    }
  }

  return auth;
};

const UserProvider: FC<Props> = ({ children }: Props) => {
  const [state, dispatch] = useReducer(userReducer, {
    auth: null,
    ready: false,
    user: null,
  });

  useEffect(() => {
    getInitialProps().then((auth: AuthState) => {
      dispatch(updateAuth(auth));

      if (!auth.accessToken) {
        dispatch(updateReady(true));
      }
    });
  }, []);

  useClientOnlyLayoutEffect(() => {
    clients.forEach((client) => {
      const { headers } = client.defaults;
      headers.common.Authorization = state.auth?.accessToken
        ? `Bearer ${state.auth.accessToken}`
        : null;
    });
  }, [state.auth?.accessToken]);

  useEffect(() => {
    broadcastChannel.postMessage(state.auth);
    store(state.auth);
  }, [state.auth]);

  useEffect(() => {
    const handler = (auth: AuthState) => {
      if (JSON.stringify(auth) !== JSON.stringify(state.auth)) {
        dispatch(auth ? updateAuth(auth) : deleteAuth());
      }
    };

    broadcastChannel.addEventListener('message', handler);

    return () => broadcastChannel.removeEventListener('message', handler);
  }, [state.auth]);

  useEffect(() => {
    const updateOrDeleteUser = async () => {
      if (state.auth?.accessToken) {
        dispatch(updateUser(await accountDetails()));
        dispatch(updateReady(true));
      } else {
        dispatch(deleteUser());
      }
    };

    updateOrDeleteUser();
  }, [state.auth?.accessToken]);

  useIntervalEffect(
    async () => {
      if (
        state.auth?.accessTokenExpiresAt &&
        dayjs().add(5, 'minute').diff(dayjs(state.auth.accessTokenExpiresAt)) >
          0
      ) {
        try {
          const response = await oauthToken({
            grantType: OauthGrantType.RefreshToken,
            refreshToken: state.auth.refreshToken,
          });

          dispatch(
            updateAuth({
              accessToken: response.access_token,
              accessTokenExpiresAt: dayjs()
                .add(response.expires_in, 'second')
                .toISOString(),
              refreshToken: response.refresh_token,
            })
          );
        } catch {
          dispatch(updateAuth(initial));
        }
      }
    },
    1000 * 60,
    [state.auth]
  );

  useIntervalEffect(
    async () => {
      if (
        state.auth &&
        (!state.auth.sessionHardRefreshTime ||
          dayjs().diff(dayjs(state.auth.sessionHardRefreshTime)) > 0)
      ) {
        try {
          if (!state.auth.accessToken) {
            return false;
          }

          const { sessionId } = await accountSession(state.auth.accessToken);

          dispatch(
            updateAuth({
              sessionId,
              sessionExpiresAt: dayjs().add(1, 'hour').toISOString(),
              sessionHardRefreshTime: dayjs().add(12, 'hour').toISOString(),
            })
          );
        } catch {
          dispatch(updateAuth(initial));
        }
      }

      return true;
    },
    1000 * 60,
    [state?.auth?.sessionHardRefreshTime]
  );

  useEffect(() => {
    const updateSessionExpiresAtEventListener = () => {
      dispatch(
        updateAuth({
          sessionExpiresAt: dayjs().add(1, 'hour').toISOString(),
        })
      );
    };

    document.body.addEventListener(
      'click',
      updateSessionExpiresAtEventListener
    );

    return () =>
      document.body.removeEventListener(
        'click',
        updateSessionExpiresAtEventListener
      );
  }, []);

  return (
    <UserDispatchContext.Provider value={dispatch}>
      <UserStateContext.Provider value={state}>
        {children}
      </UserStateContext.Provider>
    </UserDispatchContext.Provider>
  );
};

export default UserProvider;
