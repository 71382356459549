import Action from '../../../../types/action';
import BasketActions from '.';
import { State } from '../basket.state';

const replace = (state: State): Action<BasketActions> => {
  return {
    type: BasketActions.Replace,
    payload: state,
  };
};

export default replace;
