import UserActions from '.';
import Action from '../../../../types/action';

const updateReady = (ready: boolean): Action<UserActions> => {
  return {
    type: UserActions.UpdateReady,
    payload: ready,
  };
};

export default updateReady;
