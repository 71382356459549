import { useState, useEffect } from 'react';
import getBuildId from '../../services/local-api/endpoints/build-id';
import useIntervalEffect from '../use-interval-effect/use-interval-effect';
import usePrevious from '../use-previous/use-pervious';

export default function useRefreshOnNewBuild(): void {
  const tenMinutes = 10 * 60 * 1000;

  const [currentBuildId, setCurrentBuildId] = useState<string | null>(null);
  const previousBuildId = usePrevious(currentBuildId);

  useIntervalEffect(
    () => {
      getBuildId().then((buildId) => setCurrentBuildId(buildId));
    },
    tenMinutes,
    []
  );

  useEffect(() => {
    if (previousBuildId && previousBuildId !== currentBuildId) {
      window.location.reload();
    }
  }, [currentBuildId, previousBuildId]);
}
