import Action from '../../../../types/action';
import UserActions from '.';
import { AuthState } from '../user.state';

const updateAuth = (auth: Partial<AuthState>): Action<UserActions> => {
  return {
    type: UserActions.UpdateAuth,
    payload: auth,
  };
};

export default updateAuth;
