import React, { FC, ReactNode } from 'react';

interface Props {
  readonly isActive?: boolean;
  readonly children: ReactNode;
}

const AnimationToggleOverlayContent: FC<Props> = ({
  isActive,
  children,
}: Props) => {
  if (isActive) {
    return <div>{children}</div>;
  }

  return null;
};

AnimationToggleOverlayContent.defaultProps = {
  isActive: false,
};

export default AnimationToggleOverlayContent;
