import UserActions from '.';
import { UserType } from '../../../../models/user-type';
import Action from '../../../../types/action';

const updateUser = (user: UserType): Action<UserActions> => {
  return {
    type: UserActions.UpdateUser,
    payload: user,
  };
};

export default updateUser;
