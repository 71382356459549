import Action from '../../../../types/action';
import UserActions from '.';

const deleteUser = (): Action<UserActions> => {
  return {
    type: UserActions.DeleteUser,
  };
};

export default deleteUser;
