import { NextApiResponse } from 'next';
import { setCookie } from 'nookies';
import CookieKeysEnum from '../../../../models/cookie-keys-enum';
import { State } from '../basket.state';

export default function store(state: State, res: NextApiResponse = null): void {
  setCookie(
    res ? { res } : null,
    CookieKeysEnum.Basket,
    JSON.stringify({
      id: state.id,
      confirmedBackOrderSkus: state.confirmedBackOrderSkus,
      itemCount: state.itemCount,
      quote: state.quote,
      recentId: state.recentId,
      total: state.total,
    }),
    {
      maxAge: 60 * 60 * 12,
      path: '/',
    }
  );
}
