import CookieKeysEnum from '../../../../models/cookie-keys-enum';
import Primitive from '../../../../types/primitive';
import { State } from '../basket.state';
import initial from './initial';

export default function retrieve(cookies: { [key: string]: Primitive }): State {
  return CookieKeysEnum.Basket in cookies
    ? { ...initial, ...JSON.parse(cookies[CookieKeysEnum.Basket] as string) }
    : { ...initial };
}
