import Action from '../../../types/action';
import OverlayActions from './overlay.actions';
import { OverlayState } from './overlay.state';

export default function overlayReducer(
  state: OverlayState,
  action: Action<OverlayActions>
): OverlayState {
  if (action.type === OverlayActions.Close) {
    return {
      ...state,
      components: [],
      keepNav: false,
      isHeaderSearch: false,
      lockScroll: true,
      isAccount: false,
      isMultiAdd: false,
    };
  }

  if (action.type === OverlayActions.Open) {
    return {
      ...state,
      components: action.payload.isMultiAdd
        ? [...state.components, action.payload.component]
        : [action.payload.component],
      keepNav: action.payload.keepNav,
      isHeaderSearch: action.payload.isHeaderSearch,
      lockScroll: action.payload.lockScroll,
      isAccount: action.payload.isAccount,
      isMultiAdd: action.payload.isMultiAdd,
    };
  }

  if (action.type === OverlayActions.Push) {
    return {
      ...state,
      components: [...state.components, action.payload],
    };
  }

  if (action.type === OverlayActions.Pop) {
    return {
      ...state,
      components: state.components.slice(0, -1),
    };
  }

  return state;
}
