import client from '../../client-unauthenticated';

const accountGuestSession = async (): Promise<{ sessionId: string }> => {
  const { data } = await client.get<{ sessionId: string }>(
    '/account/guest-session',
    {
      params: { provider: 0 },
    }
  );

  return data;
};

export default accountGuestSession;
