import CultureCodeEnum from '../../../../../models/culture-code-enum';
import client from '../../../client';
import { BasketType } from '../../../../../models/basket-type';

export default async function get(
  session: string,
  basketId?: string
): Promise<BasketType> {
  const { data } = await client.get<BasketType>('/basket/member/get', {
    params: {
      cultureCode: CultureCodeEnum.EnGb,
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      provider: 0,
      session,
      basketId,
    },
  });

  return data;
}
