import { BroadcastChannel } from 'broadcast-channel';
import nookies from 'nookies';
import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { BasketType } from '../../../models/basket-type';
import { UserStateContext } from '../user/user.provider';
import basketLoading from './actions/basket-loading';
import replace from './actions/replace';
import updateBasket from './actions/update-basket';
import basketReducer from './basket.reducer';
import { State } from './basket.state';
import useGetBasket from './hooks/use-get-basket';
import retrieve from './persistence/retrieve';
import store from './persistence/store';

const broadcastChannel =
  typeof window !== 'undefined'
    ? new BroadcastChannel<State>('basket-provider-channel')
    : null;

export const BasketDispatchContext = createContext<Dispatch<unknown>>(
  () => null
);

export const BasketStateContext = createContext<State>(null);

export interface Props {
  children: ReactNode;
}

const BasketProvider: FC<Props> = ({ children }: Props) => {
  const { auth } = useContext(UserStateContext);
  const [state, dispatch] = useReducer(basketReducer, retrieve(nookies.get()));
  const getBasket = useGetBasket();

  useEffect(() => {
    if (state?.isLoading) {
      return;
    }

    broadcastChannel.postMessage(state);
    store(state);
  }, [state]);

  useEffect(() => {
    const handler = (receivedState: State) => {
      if (
        receivedState.total !== state.total ||
        receivedState.itemCount !== state.itemCount
      ) {
        dispatch(replace(receivedState));
      }
    };

    broadcastChannel.addEventListener('message', handler);

    return () => broadcastChannel.removeEventListener('message', handler);
  }, [state]);

  useEffect(() => {
    if (state?.basketInvalidated && !state?.isLoading && auth?.sessionId) {
      dispatch(basketLoading(true));
      getBasket(state.id)
        .then((basket) => dispatch(updateBasket(basket)))
        .catch(() => dispatch(updateBasket({ error: true } as BasketType)))
        .finally(() => dispatch(basketLoading(false)));
    }
  }, [state, auth]);

  return (
    <BasketDispatchContext.Provider value={dispatch}>
      <BasketStateContext.Provider value={state}>
        {children}
      </BasketStateContext.Provider>
    </BasketDispatchContext.Provider>
  );
};

export default BasketProvider;
