import client from '../../client-unauthenticated';

const accountSession = async (
  token: string
): Promise<{ sessionId: string }> => {
  const { data } = await client.get<{ sessionId: string }>('/account/session', {
    headers: token ? { Authorization: `Bearer ${token}` } : {},
    params: { provider: 0 },
  });

  return data;
};

export default accountSession;
