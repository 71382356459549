import { NextApiResponse } from 'next';
import { setCookie } from 'nookies';
import CookieKeysEnum from '../../../../models/cookie-keys-enum';
import { AuthState } from '../user.state';

export default function store(
  state: AuthState,
  res: NextApiResponse = null
): void {
  setCookie(
    res ? { res } : null,
    CookieKeysEnum.Session,
    JSON.stringify(state),
    {
      maxAge: 60 * 60 * 24 * 30,
      path: '/',
    }
  );
}
