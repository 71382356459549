import type { AppProps } from 'next/app';
import { AppType } from 'next/dist/shared/lib/utils';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import NextNProgress from 'nextjs-progressbar';
import Script from 'next/script';
import BasketProvider from '../components/providers/basket/basket.provider';
import NavigationProvider from '../components/providers/navigation/navigation.provider';
import OverlayProvider from '../components/providers/overlay/overlay.provider';
import DataLayerProvider from '../components/providers/data-layer/data-layer.provider';
import UserProvider from '../components/providers/user/user.provider';
import tailwindConfig from '../../tailwind.config';
import '../styles/globals.scss';

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
    DD_RUM: Record<string, unknown>;
    OnetrustActiveGroups: string;
    OneTrust: {
      initializeCookiePolicyHtml: () => void;
      ToggleInfoDisplay: () => void;
    };
    $TB: {
      hooks: {
        fireEvent: (
          event: string,
          properties: Record<string, unknown> | null,
          options: Record<string, unknown> | null
        ) => void;
      };
    };
  }
  function preval(config: TemplateStringsArray);
}

const queryClient = new QueryClient();

const App: AppType = ({ Component, pageProps }: AppProps) => (
  <>

    {pageProps.page?.analytics?.pixelHead && (
      <script
        dangerouslySetInnerHTML={{ __html: pageProps.page.analytics.pixelHead }}
      />
    )}

    {pageProps.page?.analytics?.pixelBody && (
      <noscript
        dangerouslySetInnerHTML={{ __html: pageProps.page.analytics.pixelBody }}
      />
    )}

    <script
      dangerouslySetInnerHTML={{
        __html: `window.dataLayer = window.dataLayer || [];`,
      }}
    />

    <NextNProgress
      color={tailwindConfig.theme.colors['french-blue'].DEFAULT}
      options={{ showSpinner: false }}
    />

    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <DataLayerProvider>
          <BasketProvider>
            <NavigationProvider>
              <OverlayProvider>
                <Component {...pageProps} />
              </OverlayProvider>
            </NavigationProvider>
          </BasketProvider>
        </DataLayerProvider>
      </UserProvider>
    </QueryClientProvider>
    <script
      src="https://scripts.webeo.com/compliance.webeo.js"
    ></script>
    <noscript>
      <img src="https://secure.imaginative-24.com/793919.png" style={{ display: "none" }} />
    </noscript>

    {pageProps.page?.analytics?.gtmBody && (
      <noscript
        dangerouslySetInnerHTML={{ __html: pageProps.page.analytics.gtmBody }}
      />
    )}

    <Script id="vwoCode" strategy="beforeInteractive">
      {`window._vwo_code=window._vwo_code || (function() {
        var account_id=685874,
        version = 1.5,
        settings_tolerance=2000,
        library_tolerance=2500,
        use_existing_jquery=false,
        is_spa=1,
        hide_element='body',
        hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important',
        f=false,d=document,vwoCodeEl=d.querySelector('#vwoCode'),code={use_existing_jquery:function(){return use_existing_jquery},library_tolerance:function(){return library_tolerance},hide_element_style:function(){return'{'hide_element_style'}'},finish:function(){if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=d.createElement('script');t.fetchPriority='high';t.src=e;t.type='text/javascript';t.onerror=function(){_vwo_code.finish()};d.getElementsByTagName('head')[0].appendChild(t)},getVersion:function(){return version},getMatchedCookies:function(e){var t=[];if(document.cookie){t=document.cookie.match(e)||[]}return t},getCombinationCookie:function(){var e=code.getMatchedCookies(/(?:|\\s?(vis_opt_exp
        d+combi=[;$])/gi);e=e.map(function(e){try{var t=decodeURIComponent(e);if(!/_vis_opt_exp\\d+_combi=(?:\\d+,?)
        s*$/.test(t)){return''}return t}catch(e){return''}});var i=[];e.forEach(function(e){var t=e.match(/([
        d,])/g);t&&i.push(t.join('-'))});return i.join('|')},init:function(){if(d.URL.indexOf('vwo_disable')>-1)return;window.settings_timer=setTimeout(function(){_vwo_code.finish()},settings_tolerance);var e=d.createElement('style'),t=hide_element?hide_element+'{'hide_element_style'}':'',i=d.getElementsByTagName('head')[0];e.setAttribute('id','_vis_opt_path_hides');vwoCodeEl&&e.setAttribute('nonce',vwoCodeEl.nonce);e.setAttribute('type','text/css');if(e.styleSheet)e.styleSheet.cssText=t;else e.appendChild(d.createTextNode(t));i.appendChild(e);var n=this.getCombinationCookie();this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='account_id'&u='encodeURIComponent(d.URL)'&f='+ is_spa'&vn='version(n?'&c='+n:''));return settings_timer}};window._vwo_settings_timer = code.init();return code;}());`}
    </Script>
  </>
);

export default App;
