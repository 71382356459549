import client from '../../client-unauthenticated';

type OauthResponse = {
  /* eslint-disable */
  access_token: string;
  refresh_token: string;
  expires_in: number;
  /* eslint-enable */
};

export enum OauthGrantType {
  Password = 'password',
  RefreshToken = 'refresh_token',
}

const oauthToken = async (parameters: {
  grantType: OauthGrantType;
  username?: string;
  password?: string;
  refreshToken?: string;
}): Promise<OauthResponse> => {
  const { data } = await client.post<OauthResponse>('/oauth/token', {
    grant_type: parameters.grantType,
    username: parameters.username,
    password: parameters.password,
    refresh_token: parameters.refreshToken,
  });

  return data;
};

export default oauthToken;
