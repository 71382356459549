import Action from '../../../../types/action';
import UserActions from '.';

const deleteAuth = (): Action<UserActions> => {
  return {
    type: UserActions.DeleteAuth,
  };
};

export default deleteAuth;
