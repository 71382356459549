import BasketActions from '.';
import Action from '../../../../types/action';

const basketLoading = (isLoading: boolean): Action<BasketActions> => {
  return {
    type: BasketActions.BasketLoading,
    payload: isLoading,
  };
};

export default basketLoading;
