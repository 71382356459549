import { useContext } from 'react';
import { BasketType } from '../../../../models/basket-type';
import getGuestBasket from '../../../../services/content-api/endpoints/basket/guest/get';
import getMemberBasket from '../../../../services/content-api/endpoints/basket/member/get';
import useGetSessionId from '../../user/hooks/use-get-session-id.hook';
import { UserStateContext } from '../../user/user.provider';

const useGetBasket: () => (basketId: string) => Promise<BasketType> = () => {
  const { auth } = useContext(UserStateContext);
  const getSessionId = useGetSessionId();

  return async (basketId) => {
    const basket = auth?.accessToken
      ? await getMemberBasket(await getSessionId(), basketId)
      : await getGuestBasket(await getSessionId(), basketId);

    return basket;
  };
};

export default useGetBasket;
