import React, { FC, ReactNode } from 'react';

interface Props {
  readonly children: ReactNode;
  readonly isActive?: boolean;
  readonly keepNav: boolean;
}

const AnimationToggleOverlay: FC<Props> = ({
  children,
  isActive,
  keepNav,
}: Props) => (
  <>
    {isActive && (
      <div
        className={`${
          keepNav ? 'z-30 top-header-mobile lg:top-header' : 'z-40 top-0'
        } bottom-0 fixed left-0 right-0`}
      >
        {children}
      </div>
    )}
  </>
);

AnimationToggleOverlay.defaultProps = {
  isActive: false,
};

export default AnimationToggleOverlay;
