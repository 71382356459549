import { UserType } from '../../../../models/user-type';
import client from '../../client';

const accountDetails = async (): Promise<UserType> => {
  const { data } = await client.post<UserType>('/account/details', {
    membershipProvider: 0,
  });

  return data;
};

export default accountDetails;
