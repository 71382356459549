import { useCallback, useEffect } from 'react';

export default function useScrollLock(
  isActive: boolean,
  shouldLockScroll: boolean
): void {
  const lockScroll = useCallback(() => {
    document.body.classList.add('overflow-hidden');
  }, []);

  const unlockScroll = useCallback(() => {
    document.body.classList.remove('overflow-hidden');
  }, []);

  useEffect(() => {
    if (isActive && shouldLockScroll) {
      lockScroll();
    }

    return () => {
      unlockScroll();
    };
  }, [isActive]);
}
