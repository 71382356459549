import dayjs from 'dayjs';
import { AuthState } from '../user.state';

const initial: AuthState = {
  accessTokenExpiresAt: null,
  accessToken: null,
  refreshToken: null,
  sessionId: null,
  sessionExpiresAt: dayjs().add(2, 'hour').toISOString(),
  sessionHardRefreshTime: dayjs().add(12, 'hour').toISOString(),
};

export default initial;
