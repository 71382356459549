import calculateLineItemQuantity from '../../../helpers/calculate-line-item-quantity';
import { BasketType } from '../../../models/basket-type';
import { ProductBasketLineItemType } from '../../../models/product-basket-line-item-type';
import Action from '../../../types/action';
import BasketActions from './actions';
import { State } from './basket.state';

const basketReducer = (state: State, action: Action<BasketActions>): State => {
  if (action.type === BasketActions.Replace) {
    return action.payload;
  }

  if (action.type === BasketActions.UpdateRecentBasketId) {
    return {
      ...state,
      recentId: action.payload,
    };
  }

  if (action.type === BasketActions.RemoveRecentBasketId) {
    return {
      ...state,
      recentId: null,
    };
  }

  if (action.type === BasketActions.InvalidateBasket) {
    return {
      ...state,
      basketInvalidated: true,
    };
  }

  if (action.type === BasketActions.UpdateBasket) {
    const basket = action.payload as BasketType;

    return {
      ...state,
      basket,
      basketInvalidated: false,
      itemCount:
        basket.lineItems
          ?.map(
            (item: ProductBasketLineItemType) =>
              calculateLineItemQuantity(item).total
          )
          ?.reduce((prev: number, curr: number) => prev + curr, 0) || 0,
      id: basket.id,
      total: basket.formattedTotal,
    };
  }

  if (action.type === BasketActions.Reset) {
    return {
      ...state,
      basket: null,
      confirmedBackOrderSkus: [],
      id: null,
      isLoading: false,
      itemCount: null,
      quote: null,
      total: null,
    };
  }

  if (action.type === BasketActions.RemoveQuote) {
    return {
      ...state,
      quote: null,
    };
  }

  if (action.type === BasketActions.UpdateBasketTotal) {
    return {
      ...state,
      itemCount: action.payload.basketItemCount,
      total: action.payload.basketTotal,
    };
  }

  if (action.type === BasketActions.UpdateBasketQuote) {
    return {
      ...state,
      quote: action.payload,
    };
  }

  if (action.type === BasketActions.ReplaceConfirmedBackOrderSkus) {
    return {
      ...state,
      confirmedBackOrderSkus: action.payload,
    };
  }

  if (action.type === BasketActions.BasketLoading) {
    return {
      ...state,
      isLoading: action.payload,
    };
  }

  if (action.type === BasketActions.SetLastAddedVariantSkus) {
    return {
      ...state,
      lastAddedVariantSkus: action.payload,
    };
  }

  if (action.type === BasketActions.SetMultiAddFormState) {
    return {
      ...state,
      multiAddFormState: action.payload,
    };
  }

  return state;
};

export default basketReducer;
