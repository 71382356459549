import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import GtmEventNameEnum from '../../models/gtm-event-name-enum';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../components/providers/data-layer/data-layer.provider';

export default function useFirePageChangeEvents(): void {
  const router = useRouter();
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  useEffect(() => {
    const pushVisitToDataLayer = () =>
      pushToDataLayer(GtmEventNameEnum.PageView, {
        pagePath: window.location.pathname,
        pageTitle: window.document.title,
        OnetrustActiveGroups: window.OnetrustActiveGroups,
      });

    const handleRouteChange = () => {
      window?.$TB?.hooks?.fireEvent?.('pageChange', null, { sendBeacon: true });

      pushToDataLayer(GtmEventNameEnum.HistoryChange, {
        url: window.location.href,
        OnetrustActiveGroups: window.OnetrustActiveGroups,
      });

      pushVisitToDataLayer();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    pushVisitToDataLayer();

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);
}
