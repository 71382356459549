enum GtmEventNameEnum {
  AccountNav = 'accountNav',
  AccountSubNav = 'accountSubNav',
  AddToCart = 'addToCart',
  AddressesAdd = 'addressesAdd',
  AddressesDelete = 'addressesDelete',
  AddressesEdit = 'addressesEdit',
  AddressesSearch = 'addressesSearch',
  AddressesStartNew = 'addressesStartNew',
  BannerClose = 'bannerClose',
  BannerLink = 'bannerLink',
  BasketAction = 'basketAction',
  BasketAlternative = 'basketAlternative',
  BasketAlternativeAdd = 'basketAlternativeAdd',
  BasketAlternativeReplace = 'basketAlternativeReplace',
  BasketBackorder = 'basketBackorder',
  BasketLeadTimes = 'basketLeadTimes',
  BasketPromo = 'basketPromo',
  BasketPromoError = 'basketPromoError',
  BasketSave = 'basketSave',
  BasketSingle = 'basketSingle',
  BasketView = 'basketView',
  BrandsCard = 'brandsCard',
  BrandsLink = 'brandsLink',
  BulkTemplate = 'bulkTemplate',
  BulkUpload = 'bulkUpload',
  CardApp = 'cardApp',
  CardExtraInfo = 'cardExtraInfo',
  CardHeadingCta = 'cardHeadingCTA',
  CardNews = 'cardNews',
  CardPromo = 'cardPromo',
  CardUsp = 'cardUsp',
  CarouselCta = 'carouselCta',
  CategoryCard = 'categoryCard',
  CategoryFeatureBlock = 'categoryFeatureBlock',
  CategoryFilter = 'categoryFilter',
  Checkout = 'checkout',
  CheckoutDeleteAddress = 'checkoutDeleteAddress',
  CheckoutEditAddress = 'checkoutEditAddress',
  CheckoutEditBasket = 'checkoutEditBasket',
  CheckoutError = 'checkoutError',
  CheckoutFtukExpress = 'checkoutFtukExpress',
  CheckoutFtukPlus = 'checkoutFtukPlus',
  CheckoutNewAddress = 'checkoutNewAddress',
  CheckoutOption = 'checkoutOption',
  CheckoutOutOfHoursOption = 'checkoutOutofhoursOption',
  CheckoutPrint = 'checkoutPrint',
  CheckoutRegister = 'checkoutRegister',
  CheckoutSavedAddress = 'checkoutSavedAddress',
  CheckoutStep = 'checkoutStep',
  CheckoutUpdateAddress = 'checkoutUpdateAddress',
  ContactDirections = 'contactDirections',
  ContactEmail = 'contactEmail',
  ContactExpand = 'contactExpand',
  ContactFax = 'contactFax',
  ContactPhone = 'contactPhone',
  DashboardBulkLink = 'dashboardBulkLink',
  DashboardDetailsCard = 'dashboardDetailsCard',
  DashboardResourcesCard = 'dashboardResourcesCard',
  DashboardTechsheetDownload = 'dashboardTechsheetDownload',
  DashboardTechsheetSearch = 'dashboardTechsheetSearch',
  DashboardTechsheetSelect = 'dashboardTechsheetSelect',
  Detail = 'detail',
  DetailsBenefits = 'detailsBenefits',
  DetailsDiscountDloadCsv = 'detailsDiscountDloadCsv',
  DetailsDiscountDloadDesc = 'detailsDiscountDloadDesc',
  DetailsDiscountPrint = 'detailsDiscountPrint',
  DetailsUpdate = 'detailsUpdate',
  DownloadBlock = 'downloadBlock',
  FavouritesAdd = 'favouritesAdd',
  FavouritesDelete = 'favouritesDelete',
  FavouritesNew = 'favouritesNew',
  FavouritesSearch = 'favouritesSearch',
  FooterNav = 'footerNav',
  FooterNavCard = 'footerNavCard',
  FooterSocial = 'footerSocial',
  FormAbandon = 'formAbandon',
  FormError = 'formError',
  FormProgress = 'formProgress',
  FormSuccess = 'formSuccess',
  GtmVideo = 'gtmVideo',
  HistoryChange = 'historyChange',
  InpageLink = 'inpageLink',
  InpageNavBrands = 'inpageNavBrands',
  InpageNavNews = 'inpageNavNews',
  LiteratureCallback = 'literatureCallback',
  LiteratureEnlarge = 'literatureEnlarge',
  LiteratureFilter = 'literatureFilter',
  LiteratureSearch = 'literatureSearch',
  LiteratureSection = 'literatureSection',
  LoginFail = 'loginFail',
  LoginForgotCancel = 'loginForgotCancel',
  LoginForgotPassword = 'loginForgotPassword',
  LoginForgotPasswordError = 'loginForgotPasswordError',
  LoginForgotUsername = 'loginForgotUsername',
  LoginSuccess = 'loginSuccess',
  MainNav = 'mainNav',
  MobileNavClose = 'mobileNavClose',
  MobileNavOpen = 'mobileNavOpen',
  NavBasket = 'navBasket',
  NavContact = 'navContact',
  NavLogin = 'navLogin',
  NavSubMenu = 'navSubMenu',
  NavSubMenuBack = 'navSubMenuBack',
  NavSubMenuCard = 'navSubMenuCard',
  OrderReorder = 'OrderReorder',
  OrderTrack = 'OrderTrack',
  OrderView = 'OrderView',
  OrdersFilterAddress = 'ordersFilterAddress',
  OrdersFilterStatus = 'ordersFilterStatus',
  OrdersSearch = 'ordersSearch',
  PageView = 'pageView',
  ProductDownloadCad = 'productDownloadCad',
  ProductDownloadTechsheet = 'productDownloadTechsheet',
  ProductFavError = 'productFavError',
  ProductFavExisting = 'productFavExisting',
  ProductFavNew = 'productFavNew',
  ProductFavourite = 'ProductFavourite',
  ProductFeatureBlock = 'productFeatureBlock',
  ProductTechsheet = 'productTechsheet',
  ProductUpdate = 'productUpdate',
  Purchase = 'purchase',
  QuickAdd = 'quickAdd',
  QuickCustomer = 'quickCustomer',
  QuickError = 'quickError',
  QuickExtra = 'quickExtra',
  QuickSearch = 'quickSearch',
  RegisterAbandon = 'registerAbandon',
  RegisterProgressStep = 'registerProgressStep',
  RemoveFromCart = 'removeFromCart',
  RequestDespatchNote = 'requestDespatchNote',
  RequestInvoice = 'requestInvoice',
  RequestProduct = 'requestProduct',
  RequestReturn = 'requestReturn',
  ScheduledAdd = 'scheduledAdd',
  ScheduledDelete = 'scheduledDelete',
  ScheduledEdit = 'scheduledEdit',
  ScheduledSetup = 'scheduledSetup',
  ScheduledView = 'scheduledView',
  SearchNoResult = 'searchNoResult',
  SearchRefine = 'searchRefine',
  SearchSelect = 'searchSelect',
  SearchStart = 'searchStart',
  SearchTechSheet = 'searchTechSheet',
  SignpostCard = 'signpostCard',
  SocialShare = 'socialShare',
}

export default GtmEventNameEnum;
