const plugin = require('tailwindcss/plugin');

module.exports = {
  purge: {
    content: [
      './src/pages/**/*.{js,ts,jsx,tsx}',
      './src/components/**/*.{js,ts,jsx,tsx}',
    ],
    options: {
      safelist: [/\b\w*bg-\w*\b/, /\b\w*text-\w*\b/, /\b\w*border-\w*\b/],
    },
  },
  darkMode: false, // or 'media' or 'class'
  theme: {
    colors: {
      'french-blue': {
        dark: '#155c89',
        'dark-rgb': '21, 92, 137',
        DEFAULT: '#0075bf',
        light: '#309add',
        'light-rgb': '48, 154, 221',
        rgb: '0, 117, 191',
        contrast: '#ffffff',
        'contrast-rgb': '255, 255, 255',
        'secondary-cta-hover': 'rgba(0, 117, 191, .1)',
      },
      black: {
        dark: '#000000',
        'dark-rgb': '0, 0, 0',
        DEFAULT: '#000000',
        light: '#333333',
        'light-rgb': '51, 51, 51',
        rgb: '0, 0, 0',
        contrast: '#ffffff',
        'contrast-rgb': '255, 255, 255',
      },
      white: {
        dark: '#f2f2f2',
        'dark-rgb': '242, 242, 242',
        DEFAULT: '#ffffff',
        light: '#ffffff',
        'light-rgb': '255, 255, 255',
        rgb: '255, 255, 255',
        contrast: '#000000',
        'contrast-rgb': '0, 0, 0',
      },
      'medium-blue': {
        DEFAULT: '#DFEEF8',
      },
      'light-blue': {
        dark: '#d8e9f6',
        'dark-rgb': '216, 233, 246',
        DEFAULT: '#edf5fb',
        light: '#ffffff',
        'light-rgb': '255, 255, 255',
        rgb: '237, 245, 251',
        contrast: '#000000',
        'contrast-rgb': '0, 0, 0',
      },
      'lemon-meringue': {
        dark: '#ecdca3',
        'dark-rgb': '236, 220, 163',
        DEFAULT: '#f0e4b8',
        light: '#f4eccd',
        'light-rgb': '244, 236, 205',
        rgb: '240, 228, 184',
        contrast: '#000000',
        'contrast-rgb': '0, 0, 0',
      },
      floral: {
        dark: '#f8f3dc',
        'dark-rgb': '248, 243, 220',
        DEFAULT: '#fcfaf1',
        light: '#ffffff',
        'light-rgb': '255, 255, 255',
        rgb: '252, 250, 241',
        contrast: '#000000',
        'contrast-rgb': '0, 0, 0',
      },
      turquoise: {
        dark: '#0096af',
        'dark-rgb': '0, 150, 175',
        DEFAULT: '#00acc8',
        light: '#00c2e2',
        'light-rgb': '0, 194, 226',
        rgb: '252, 250, 241',
        contrast: '#ffffff',
        'contrast-rgb': '255, 255, 255',
      },
      charcoal: {
        dark: '#424b4b',
        'dark-rgb': '66, 75, 75',
        DEFAULT: '#4e5859',
        light: '#5a6567',
        'light-rgb': '90, 101, 103',
        rgb: '78, 88, 89',
        contrast: '#ffffff',
        'contrast-rgb': '255, 255, 255',
      },

      invalid: {
        dark: '#c62a3f',
        'dark-rgb': '198, 42, 63',
        DEFAULT: '#d4364b',
        rgb: '212, 54, 75',
        light: '#d84b5e',
        'light-rgb': '216, 75, 94',
        contrast: '#ffffff',
        'contrast-rgb': '255, 255, 255',
        bg: '#ffe6eb',
        'bg-rgb': '255, 230, 235',
      },
      focus: {
        DEFAULT: '#88cbf5',
        rgb: '136, 203, 245',
        contrast: '#ffffff',
        'contrast-rgb': '255, 255, 255',
      },
      success: {
        DEFAULT: '#6fa06b',
        rgb: '111, 160, 107',
        contrast: '#ffffff',
        'contrast-rgb': '255, 255, 255',
      },
      'hose-builder-gray': {
        DEFAULT: '#FAFAFA',
      },
      grey: {
        20: '#fafafa',
        50: '#f2f2f2',
        100: '#e5e5e5',
        200: '#cccccc',
        300: '#b2b2b2',
        400: '#999999',
        500: '#7f7f7f',
        600: '#666666',
        700: '#4c4c4c',
        800: '#333333',
        900: '#1a1a1a',
      },
      'check-green': '#00AB5C',
      'light-green': '#00ab5c26',
      'alert-orange': '#F0A148',
    },
    fontFamily: {
      display: ['Inter', 'system-ui', 'sans-serif'],
      body: ['Inter', 'system-ui', 'sans-serif'],
    },
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      md: '1rem',
      lg: '1.25rem',
      xl: '1.375rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.375rem',
      '5xl': '2.5rem',
      '6xl': '3rem',
      '7xl': '4.375rem',
      '8xl': '5.625rem',
    },
    boxShadow: {
      sm: '0 1px 2px 0 rgba(0, 0, 0, 0.12)',
      DEFAULT: '0 1px 3px 0 rgba(0, 0, 0, .12), 0 1px 2px 0 rgba(0, 0, 0, .08)',
      md: '0 3px 6px 0px rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .09)',
      lg: '0 8px 15px -2px rgba(0, 0, 0, .12), 0 4px 6px -2px rgba(0, 0, 0, .08)',
      xl: '0 16px 25px -3px rgba(0, 0, 0, 0.12), 0 10px 10px -5px rgba(0, 0, 0, .08)',
      '2xl': '0 25px 50px -10px rgba(0, 0, 0, .25)',
      '3xl': '0 35px 60px -15px rgba(0, 0, 0, .3)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, .06)',
      none: 'none',
    },
    extend: {
      borderWidth: {
        3: '3px',
      },
      maxHeight: {
        76: '19rem',
        'screen-minus-header': 'calc(100vh - 7.875rem)',
      },
      maxWidth: {
        'screen-2xl': '1336px',
      },
      spacing: {
        '1px': '1px',
        '2px': '2px',
        '3px': '3px',
        15: '3.75rem',
        18: '4.5rem',
        160: '40rem',
        192: '48rem',
        full: '100%',
        'ratio-16-9': '56.25%',
        'component-spacing': '3rem',
        'component-spacing-content': '2rem',
        'component-spacing-mobile': '1.5rem',
        header: '8.375rem',
        'header-mobile': '8.875rem',
        'header-no-nav': '5.375rem',
      },
      transitionDuration: {
        400: '400ms',
      },
      zIndex: {
        '-1': '-1',
        35: '35',
        60: '60',
      },
    },
  },
  variants: {
    extend: {
      borderWidth: ['last'],
      margin: ['last'],
    },
  },
};
